import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../axiosInstance';
import './SelectStudentProfile.scss';
import { Link, useNavigate } from 'react-router-dom';
import { getAllTypeFormData } from '../../network/api';
import moment from 'moment';

interface StudentTypeProps {
  formData: any;
  setFormData: (data: any) => void;
}

const SelectProfile: React.FC<StudentTypeProps> = ({ formData, setFormData }) => {
  const [linkContacts, setLinkContacts] = useState<any>([]);
  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);
  const [selectedStudentContactID, setSelectedStudentContactID] = useState<string | null>(null);
  const [matchedTypeformData, setMatchedTypeformData] = useState([]);
  const [getTypeFormData, setTypeFormData] = useState<any>([]);
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  const fetchLinkContacts = async () => {
    try {
      const response = await axiosPrivate.get(`/contact/linked-accounts/child`);

      setLinkContacts(response.data.data);
      setMessage(response.data.message);

    } catch (error) {
      setMessage('Error fetching contacts');
    }
  };

  const fetchContactandTypeForm = async () => {
    const typeform = await getAllTypeFormData()
    setTypeFormData(typeform)
  }

  useEffect(() => {
    fetchLinkContacts();
    fetchContactandTypeForm()
    
    const storedData = {
      student: sessionStorage.getItem('selectedStudent'),
      studentContactID: sessionStorage.getItem('student_contact_id'),
      typeformData: sessionStorage.getItem('typeform_data'),
      contact_sf_id: sessionStorage.getItem('contact_sf_id')
    };
  
    setFormData((prevData: any) => ({
      ...prevData,
      student_uuid: storedData.student || prevData.student_uuid,
      student_contact_id: storedData.studentContactID || prevData.student_contact_id,
      typeform_data: storedData.typeformData ? JSON.parse(storedData.typeformData) : prevData.typeform_data,
      contact_sf_id: storedData.contact_sf_id || prevData.contact_sf_id
    }));
  
    if (storedData.student) setSelectedStudent(storedData.student);
    if (storedData.studentContactID) setSelectedStudentContactID(storedData.studentContactID);
  }, []);
  
  const selectContactHandler = (contact_id: string, sf_id: string, student_first_name: string) => {
    const newSelectedStudent = selectedStudent === contact_id ? null : contact_id;

    const matchedContact = linkContacts.find((contact: { student_contacts: { uuid: string; id: string; }; }) => 
      contact.student_contacts.uuid === newSelectedStudent
    );
  
    const newSelectedStudentContactID = matchedContact ? matchedContact.student_contacts.id : null;
    const matchedTypeform = getTypeFormData?.data?.filter((form: { student_uuid: string }) =>
      form.student_uuid === newSelectedStudent
    );
  
    const newSelectedStudentTypeformData = Array.isArray(matchedTypeform) && matchedTypeform.length > 0 ? matchedTypeform : null;
  
    updateFormData(newSelectedStudent, newSelectedStudentContactID, newSelectedStudentTypeformData, sf_id, student_first_name);
    updateSessionStorage(newSelectedStudent, newSelectedStudentContactID, newSelectedStudentTypeformData, sf_id, student_first_name);
  };
  
  const updateFormData = (student_uuid: string | null, student_contact_id: string | null, typeform_data: any, sf_id: string, student_first_name: string) => {
    setSelectedStudent(student_uuid);
    setSelectedStudentContactID(student_contact_id);
    setMatchedTypeformData(typeform_data);
    
    setFormData({
      ...formData,
      student_uuid,
      student_contact_id,
      typeform_data,
      contact_sf_id: sf_id,
      student_first_name
    });
  };

  const updateSessionStorage = (student_uuid: string | null, student_contact_id?: string | null, typeform_data?: any, sf_id?: string, student_first_name?: string) => {
    if (student_uuid) {
      sessionStorage.setItem('selectedStudent', student_uuid);
      sessionStorage.setItem('student_contact_id', student_contact_id || '');
      sessionStorage.setItem('typeform_data', JSON.stringify(typeform_data || ""));
      sessionStorage.setItem('contact_sf_id', sf_id || '')
      sessionStorage.setItem('student_first_name', student_first_name || '')
    } else {
      sessionStorage.removeItem('selectedStudent');
      sessionStorage.removeItem('student_contact_id');
      sessionStorage.removeItem('typeform_data');
      sessionStorage.removeItem('contact_sf_id');
    }
  };

  const getInitials = (firstName: string | undefined | null, lastName: string | undefined | null) => {
    const firstInitial = firstName?.charAt(0).toUpperCase() || '';
    const lastInitial = lastName?.charAt(0).toUpperCase() || '';
    return `${firstInitial}${lastInitial}`;
  };

  const campus = formData?.decodedProgram
  const session = `${formData?.selectedSession?.session_name} (${moment.utc(formData?.selectedSession?.start_date).format('MM/DD/YYYY')} - ${moment.utc(formData?.selectedSession?.end_date).format('MM/DD/YYYY')}) ${formData?.selectedProgram?.name}` 

  return (
    <> 
      <h2 className='mx-3' style={{ margin: '45px 0 30px' }}>{campus}: {session}</h2>
      <div className='mx-1'>
        {linkContacts.length === 0 ? (
          <div className="contact-list">
            <div className='add-contact-btn'>
              <i className="fa-solid fa-plus"></i>
                <button type="button" className="btn btn-primary" onClick={() => navigate('/add-contact/47821104', { state: { page: 'enrollment', sub_type: 'Child/Traveler' }})}>Add Member</button>
            </div>
          </div>
        ) : (
          <div className="contact-list">
            {linkContacts.map((contact: any) => {
              const isActiveStudent = selectedStudent === contact.contact_id;
              const initials = getInitials(contact.student_contacts.first_name, contact.student_contacts.last_name);
              const contact_sf_id = contact?.student_contacts?.sf_id || ''

              const fullName = `${contact?.student_contacts?.first_name} ${contact?.student_contacts?.last_name}`

              return (
                <div
                  key={contact.contact_id}
                  className={`student-selection mt-3 ${isActiveStudent ? 'selected' : ''}`}
                  onClick={() => selectContactHandler(contact.contact_id, contact_sf_id, fullName)}
                >
                  {contact?.student_contacts?.profile_url ? (
                    <img
                      src={`${contact?.student_contacts?.profile_url}`}
                      alt='profile'
                      className='rounded-circle'
                    />
                  ) : (
                    <div className='no-pic' 
                    style={{ 
                      height: '100px', 
                      width: '100px', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      fontSize: '40px', 
                      fontWeight: 'bold' }}
                      >
                      {initials}
                    </div>
                  )}
                  <div className='contact-information'>
                    <div className="d-flex">
                      <div className="fw-bold me-2">Name:</div>
                      <div className='card-text'>{contact.student_contacts.first_name}</div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-2">Email:</div>
                      <div className='card-text'>{contact.student_contacts.email}</div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-2">Relationship:</div>
                      <div className='text-capitalize card-text'>{contact.relationship}</div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-2">Phone:</div>
                      <div className='text-capitalize card-text'>{contact.student_contacts.phone}</div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className='add-contact-btn'>
              <i className="fa-solid fa-plus"></i>
              <button type="button" className="btn btn-primary" onClick={() => navigate('/add-contact/47821104', { state: { page: 'enrollment', sub_type: 'Child/Traveler' } })}>
                Add Member
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectProfile;
