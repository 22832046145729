import React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

interface ApproveDenyRequestModalProps {
  show: boolean;
  handleClose: () => void;
  modalMessage: string;
  isLoading: boolean;
  showSuccessMessage: { success: string, error: string };
  handleApproveAndDenyRequest: (studentUUID: string, programUUID: string, formID: string, getAction: any, modalMessage: string) => void;
  formData: any;
  getAction: any;
}

const ApproveDenyRequestModal: React.FC<ApproveDenyRequestModalProps> = ({
  show,
  handleClose,
  modalMessage,
  isLoading,
  showSuccessMessage,
  handleApproveAndDenyRequest,
  formData,
  getAction,
}) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Confirmation</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to {modalMessage} the request?
      {showSuccessMessage.success && (
        <Alert variant="success" className="mt-3">{showSuccessMessage.success}</Alert>
      )}
      {showSuccessMessage.error && (
        <Alert variant="danger" className="mt-3">{showSuccessMessage.error}</Alert>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>Cancel</Button>
      <Button variant="primary" disabled={isLoading} onClick={() => handleApproveAndDenyRequest(formData.studentUUID, formData.programUUID, formData.formID, getAction, modalMessage)}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ApproveDenyRequestModal;