import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Traveler } from './BillingInvoice';
import moment from 'moment';
import { currencyFormat } from '../../utils/funcHelpers';
import './PayLedger.scss'
import { axiosPrivate } from '../../axiosInstance';
import { Alert, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import useAppRequests from '../modules/application/Hooks/useAppRequests';
import PaymentMethodInputsV2 from './PaymentMethodInputV2';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../utils';
import ScrollArrow from './ScrollDownArrow';

export const methodType = [
  {name: 'Credit Card', type: 'creditCard', label: 'credit_card'},
  {name: 'Debit Card', type: 'debitCard', label: 'debit_card'},
  {name: 'Bank Account', type: 'bankAccount', label: 'bank_account'},
]

interface LocationState {
  selectedPrograms: Traveler[];
}

interface Address {
  address: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
}

export interface Payment {
  id: string;
  orbipay_id: string;
  paymentType: PaymentType 
  subType: string; // This could also be more specific, e.g., 'checking' | 'savings'
  routingNumber: string;
  cardNumber: string; // Consider changing to optional if it's not always a credit card
  cardType?: string | null; // Optional, as it can be null
  expireDates?: string | null; // Optional, as it can be null
  isActive: boolean;
  address: Address;
  account_name: string;
}

type CreditCardInfo = {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  billingAddress: string;
  billingCity: string;
  billingStateProvince: string;
  billingPostalCode: string;
  billingCountry: string;
};

type BankAccountInfo = {
  nameOnCard: string;
  routingNumber: string;
  accountNumber: string;
  bankType: 'checking' | 'savings';
};

type PaymentType = 'creditCard' | 'bankAccount' | 'debitCard';

type Message = {
  success: string;
  error: string;
}

const validationSchema = Yup.object().shape({
  payment_info: Yup.object().shape({
    payment_type: Yup.string().required('Payment type is required'),

    // Shared validation for both credit and debit cards
    creditCardInfo: Yup.object().when('payment_type', {
      is: (method: string) => method === 'creditCard' || method === 'debitCard', // Apply validation only if a new credit card is selected
      then: Yup.object({
        nameOnCard: Yup.string().required('Name on Card is required'),
        cardNumber: Yup.string().required('Card Number is required'),
        cardExpiryMonth: Yup.string().required('Expiry Month is required'),
        cardExpiryYear: Yup.string().required('Expiry Year is required'),
        cardCvv: Yup.string().required('CVV is required'),
        billingAddress: Yup.string().required('Billing Address is required'),
        billingCountry: Yup.string().required('Country is required'),
        billingStateProvince: Yup.string().required('State/Province is required'),
        billingPostalCode: Yup.string().required('Postal Code is required'),
        billingCity: Yup.string().required('City is required'),
      }),
      otherwise: Yup.object().notRequired(), // Do not validate if existing account is selected 
    }),

    // Bank Account Validation
    bankAccountInfo: Yup.object().when('payment_type', {
      is: 'bankAccount',
      then: Yup.object({
        nameOnCard: Yup.string().required('Name on Account is required'),
        routingNumber: Yup.string().required('Routing Number is required'),
        accountNumber: Yup.string().required('Account Number is required'),
        bankType: Yup.string().required('Account Type is required'),
      }),
      otherwise: Yup.object().notRequired(), // Skip validation for existing accounts
    }),
  }),

  // Programs to Pay Validation
  programs_to_pay: Yup.array().of(
    Yup.object().shape({
      pay_amount: Yup.number().required('Amount is required').positive('Amount must be positive'),
    })
  ).min(1, 'You must select at least one program to pay for'),
});


const PayLedger = () => {
  const location = useLocation();
  const { client } = useParams()
  const state = location.state as LocationState;
  const selectedPrograms = state?.selectedPrograms || [];
  const { getAllPaymentMethods } = useAppRequests();
  const {
    data: paymentMethod,
    isLoading: isPaymentMethodLoading
  } = useQuery<any>("getAllPaymentMethods", getAllPaymentMethods);
  const navigate = useNavigate()  
  const [pMethod, setPMethod] = useState<Payment[]>([])
  const [paymentType, setPaymentType] = useState<PaymentType>('bankAccount');
  const [totalAmount, setTotalAmount] = useState(0)
  const [selectPMethod, setSelectPMethod] = useState<string>('');
  const [selectedFunding, setSelectedFunding] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(isPaymentMethodLoading);
  const [message, setMessage] = useState<Message>({
    success: '', 
    error: ''
  })
  const [creditCardInfo, setCreditCardInfo] = useState<CreditCardInfo>({
    nameOnCard: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardCvv: '',
    billingAddress: '',
    billingCity: '',
    billingStateProvince: '',
    billingPostalCode: '',
    billingCountry: ''
  });
  
  const [bankAccountInfo, setBankAccountInfo] = useState<BankAccountInfo>({
    nameOnCard: '',
    routingNumber: '',
    accountNumber: '',
    bankType: 'checking'
  });

  const [paymentAmounts, setPaymentAmounts] = useState<{ program_uuid: string; pay_amount: string }[]>(
    selectedPrograms.map((program: Traveler) => ({ program_uuid: program.program_uuid, pay_amount: '' })) 
  );

  const handleAmountChange = (program_uuid: string, value: string) => {
    setPaymentAmounts((prevAmounts) =>
      prevAmounts.map((program) =>
        program.program_uuid === program_uuid ? { ...program, pay_amount: value } : program
      )
    );
  };

  const handlePaymentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentType(e.target.id as PaymentType);
  };

  const handlePMethodSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectPMethod(e.target.value);
    setSelectedFunding(e.target.value);
  };

  const handleInputChange = <
    T extends CreditCardInfo | BankAccountInfo
  >(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    setInfo: React.Dispatch<React.SetStateAction<T>>
  ) => {
    const { name, value } = e.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  
  useEffect(() => {
    if (paymentMethod && paymentMethod?.status === 200) {
      setPMethod(paymentMethod.data?.payment_methods);
      setIsLoading(false);
    }
  }, [paymentMethod])

  const handlePrevStep = () => {
    navigate('/billing-and-invoice/47821104')
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const newFormData = {
      payment_info: {
        isActive: false,
        bankAccountInfo: {
          ...bankAccountInfo,
        },
        creditCardInfo: {
          ...creditCardInfo,
        },
        payment_type: paymentType,
      },
      programs_to_pay: [...paymentAmounts],
      existingPaymentMethod: selectedFunding.includes('funding_') ? selectedFunding : '',
    };
    if (!selectedFunding.includes('funding')) {
      try {
        await validationSchema.validate(newFormData, { abortEarly: false });
      } catch (error: any) {
        setIsLoading(false);
        if (error.name === 'ValidationError') {
          setMessage({ error: error.errors.join(', '), success: '' });
          return;
        }
      }
    }
  
    try {
      const response = await axiosPrivate.post('/pay-program', newFormData);
      if (response.status === 200) {
        setMessage({ success: response.data.message, error: '' });
        setTimeout(() => {
          setMessage({ success: '', error: '' });
          navigate(`/dashboard/${client}`);
        }, 5000);
      }
    } catch (error: any) {
      setMessage({
        error: "Your payment was not processed successfully. Please review your payment details or try an alternative payment method. If you believe this is an error, we recommend checking with your bank or payment provider for more information.",
        success: '',
      });
      setTimeout(() => {
        setIsLoading(false);
        setMessage({ success: '', error: '' });
      }, 120000);
    } finally {
      setTimeout(() => {
        setIsLoading(false)
        setMessage({ success: '', error: '' });
      }, 120000);
    }
  };

  const calculateTotalAmount = () => {
    const total = paymentAmounts.reduce((acc, program) => acc + (parseFloat(program.pay_amount) || 0), 0);
    setTotalAmount(total);
  };

  useEffect(() => {
    calculateTotalAmount(); 
  }, [paymentAmounts]);

  const pMethodSaved = (!pMethod) ? null : pMethod?.filter((pm) => pm.isActive) 
  const pMethodSavedCredit = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'creditCard')
  const pMethodSavedACH = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'bankAccount')
  const pMethodSavedDebit = (!pMethod) ? null : pMethod?.filter((pm) => pm.paymentType === 'debitCard')

  let ccCount = 0
  let ACHCount = 0
  let dcCount = 0
  if (pMethodSavedCredit)  ccCount = pMethodSavedCredit.length
  if (pMethodSavedACH) ACHCount = pMethodSavedACH.length
  if (pMethodSavedDebit) dcCount = pMethodSavedDebit.length

  const serviceFee = paymentType === 'creditCard' ? totalAmount * 0.0275 : 0;
  const amountDueNow = totalAmount + serviceFee;
  
  return (
    <>
    <div style={{ background: 'white', padding: '50px 0 20px', top: 0, position: 'fixed', zIndex: 1000 }} className='d-flex w-75 align-items-center px-10'>
      <div style={{ width: 150 }}>
        <Button className='w-100' style={{ fontSize: 20 }}  variant="secondary" type="button" onClick={handlePrevStep}>
            Previous
        </Button>
      </div>
      <div style={{ background: '#f7942a' }} className='d-flex justify-content-center rounded w-75 mx-auto col-md-12'>
        <div className='text-center w-100 mx-5 py-2 text-light border-radius-20'>
          <h1 className='fw-bolder fs-2x text-light'>Pay the Remaining Amount</h1>
        </div>
      </div>
    </div>

    <div className="container mx-auto" style={{ position: 'relative', margin: '100px 0 0' }}>
      <h4 className="mb-3">
        <strong>
          Please select a payment method: Credit Card includes a 2.75% service fee.
        </strong>
      </h4>
      <div className='d-flex flex-column flex-md-row gap-3'>
        <div className="accordion col-md-3 mb-5" id="paymentMethodAccordionWrapper">
          {methodType.map((method) => (
            <div className="accordion-item" key={method.type}>
            <h2 className="accordion-header" id={`heading_${method.type}`}>
              <button
                className={`accordion-button fs-4 ${paymentType === method.type ? '' : 'collapsed'}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse_${method.type}`}
                aria-expanded={paymentType === method.type ? 'true' : 'false'}
                aria-controls={`collapse_${method.type}`}
                onClick={(e: any) => handlePaymentTypeChange(e)}
                id={method.type}
              >
                {method.name}
              </button>
            </h2>
            <div
              id={`collapse_${method.type}`}
              className={`accordion-collapse collapse ${paymentType === method.type ? 'show' : ''}`}
              aria-labelledby={`heading_${method.type}`}
              data-bs-parent="#paymentMethodAccordion"
            >
              <div className="accordion-body">
                <PaymentMethodInputsV2
                  paymentType={paymentType}
                  selectPMethod={selectPMethod}
                  pMethodSaved={pMethodSaved}
                  pMethodSavedCredit={pMethodSavedCredit}
                  pMethodSavedACH={pMethodSavedACH}
                  pMethodSavedDebit={pMethodSavedDebit}
                  ccCount={ccCount}
                  ACHCount={ACHCount}
                  dcCount={dcCount}
                  handlePMethodSelect={handlePMethodSelect}
                  handleInputChange={handleInputChange}
                  creditCardInfo={creditCardInfo}
                  bankAccountInfo={bankAccountInfo}
                  setCreditCardInfo={setCreditCardInfo}
                  setBankAccountInfo={setBankAccountInfo}
                />
              </div>
            </div>
          </div>
          ))}       
        </div>
        {(selectedFunding === 'bankAccount' && paymentType === 'bankAccount') && (
          <div className='col-md-6'>
            <h4>Bank Account Information:</h4>
            <div className='mb-3'>
              <label className='form-label'>Name on Account</label>
              <input
                type='text'
                name='nameOnCard'
                className='form-control form-control-solid form-control-lg'
                placeholder='Name on Account'
                value={bankAccountInfo.nameOnCard}
                onChange={(e) =>
                  handleInputChange(e, setBankAccountInfo)
                }
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Routing Number</label>
              <input
                type='text'
                name='routingNumber'
                className='form-control form-control-solid form-control-lg'
                placeholder='Routing Number'
                value={bankAccountInfo.routingNumber}
                onChange={(e) =>
                  handleInputChange(e, setBankAccountInfo)
                }
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Account Number</label>
              <input
                type='text'
                name='accountNumber'
                className='form-control form-control-solid form-control-lg'
                placeholder='Account Number'
                value={bankAccountInfo.accountNumber}
                onChange={(e) =>
                  handleInputChange(e, setBankAccountInfo)
                }
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Account Type</label>
              <select
                name='bankType'
                className='form-control form-control-solid form-control-lg'
                value={bankAccountInfo.bankType}
                onChange={(e) => handleInputChange(e, setBankAccountInfo)}
              >
                <option value='checking'>Checking</option>
                <option value='savings'>Savings</option>
              </select>
            </div>
          </div>
        )}

        {((selectedFunding === 'creditCard' && paymentType === 'creditCard') || (selectedFunding === 'debitCard' && paymentType === 'debitCard')) && (
          <div style={{ flex: 1 }}>
            <h4>Card Information</h4>
              <div className="row mb-3">
              <div className="col-md-6">
              <label className="form-label">Name on Card</label>
              <input
                type="text"
                name="nameOnCard"
                className="form-control form-control-solid form-control-lg"
                placeholder="Name on Card"
                value={creditCardInfo.nameOnCard}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />
              </div>

            <div className='d-flex flex-column mb-7 fv-row col-md-6'>
              <label className='required fs-6 fw-bold form-label mb-2'>Card Number</label>
              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control form-control-solid form-control-lg'
                  placeholder='Enter card number'
                  name='cardNumber'
                  value={creditCardInfo.cardNumber}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />

                <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                    alt=''
                    className='h-25px'
                  />
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                    alt=''
                    className='h-25px'
                  />
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                    alt=''
                    className='h-25px'
                  />
                </div>
              </div>
            </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label className="form-label">Expiry Month</label>
                <select
                  name="cardExpiryMonth"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="MM"
                  value={creditCardInfo.cardExpiryMonth}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                >
                  <option value='' disabled>Select Month</option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                </select>
              </div>
              <div className="col-md-4">
                <label className="form-label">Expiry Year</label>
                <select
                  name="cardExpiryYear"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="YYYY"
                  value={creditCardInfo.cardExpiryYear}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                >
                  <option value='' disabled>Select expiry year</option>
                  <option value='2021'>2021</option>
                  <option value='2022'>2022</option>
                  <option value='2023'>2023</option>
                  <option value='2024'>2024</option>
                  <option value='2025'>2025</option>
                  <option value='2026'>2026</option>
                  <option value='2027'>2027</option>
                  <option value='2028'>2028</option>
                  <option value='2029'>2029</option>
                  <option value='2030'>2030</option>
                  <option value='2031'>2031</option>
                </select>
              </div>
              <div className="col-md-4">
                <label className="form-label">CVV</label>
                <input
                  type="text"
                  name="cardCvv"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="CVV"
                  value={creditCardInfo.cardCvv}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Billing Address</label>
              <input
                type="text"
                name="billingAddress"
                className="form-control form-control-solid form-control-lg"
                placeholder="Billing Address"
                value={creditCardInfo.billingAddress}
                onChange={(e) => handleInputChange(e, setCreditCardInfo)}
              />
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                  <label className="form-label">Country</label>
                  <select
                    name="billingCountry"
                    className="form-select form-select-lg form-select-solid"
                    onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  >
                    <option value="">Select Country</option>
                    {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => (
                      <option key={c.alpha_3 + '_' + idx} value={c.alpha_3}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              <div className="col-md-6">
                <label className="form-label">State/Province</label>
                <select
                  name="billingStateProvince"
                  className="form-select form-select-lg form-select-solid"
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                  disabled={!creditCardInfo.billingCountry}
                >
                  <option value="">Select State/Province</option>
                  {COUNTRIES_ISO_3166_ALPHA_3.find((c) => c.alpha_3 === creditCardInfo.billingCountry)?.divisions?.map((d) => (
                    <option key={d.code} value={d.code}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Postal Code</label>
                <input
                  type="text"
                  name="billingPostalCode"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="Postal Code"
                  value={creditCardInfo.billingPostalCode}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">City</label>
                <input
                  type="text"
                  name="billingCity"
                  className="form-control form-control-solid form-control-lg"
                  placeholder="City"
                  value={creditCardInfo.billingCity}
                  onChange={(e) => handleInputChange(e, setCreditCardInfo)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      
      {selectedPrograms.length === 0 ? (
        <p>No programs selected for payment.</p>
      ) : (
      <form className="payment-form" onSubmit={onSubmit}>
        <div className='selected-programs'>
          {selectedPrograms.map((program: any) => {
            const campusName = program?.selected_program?.programData.campus;
            const tuitionName = program?.student_type;
            const sessionName = `${program?.selected_session?.session_name} (${moment.utc(program.selected_session.start_date).format('MM/DD/YYYY')} - ${moment.utc(program.selected_session.end_date).format('MM/DD/YYYY')})`;
            const programName = program.selected_program?.name;
            const getPayment = program?.ledger_transaction ? program?.ledger_transaction.filter((item: { transaction_type: string; }) => item.transaction_type === 'payment') : [];
            const getAllDiscounts = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => ['discount', 'scholarship'].includes(item.transaction_type)) : []
            const totalPayment = getPayment.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
            const getAllPayable = program?.ledger_transaction ?  program?.ledger_transaction.filter((item: { transaction_type: string; }) => !['payment', 'surcharge', 'revoked', 'discount', 'scholarship', 'removed'].includes(item.transaction_type)) : [];
            const allDiscounts = getAllDiscounts.reduce((total: number, transaction: { paid: number; }) => total + Math.abs(transaction.paid), 0);
            const overallPayment = getAllPayable.reduce((total: number, transaction: { paid: number; }) => total + transaction.paid, 0);
            const totalBalanceDue = (overallPayment - (totalPayment + allDiscounts));
            const latestDue = totalBalanceDue !== 0 && program?.due_schedules ? program?.due_schedules : null;  

            return (
              <div key={program.id}>
                <div className="card p-3">
                  <div className='text-capitalize fs-4'>{campusName}</div>
                  <div className='text-capitalize fs-4'>{tuitionName} Student</div>
                  <div className='text-capitalize fs-4'> {sessionName}</div>
                  <div className='text-capitalize fs-4'>{programName}</div>
                  <p className="fs-4 text-muted">Next Payment Due: {moment.utc(latestDue[0]?.due_date).format('D MMMM YYYY')} - {currencyFormat.format(latestDue[0]?.balance)}</p>
                  <p className="card-text fs-4">Outstanding Balance: <strong>{currencyFormat.format(totalBalanceDue)}</strong></p>

                  <div className="form-group fs-4">
                    <label>Payment Amount for this Program</label>
                    <input
                      type="number"
                      className="form-control fs-4"
                      id={`amount-${program.id}`}
                      onChange={(e) => handleAmountChange(program.program_uuid, e.target.value)}
                      min="0"
                      max={totalBalanceDue}
                      required
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="card col-md-6 mt-3 p-2 rounded">
          <table className="card-body">
            <tbody>
              <tr className="fs-5">
                <td>Chosen Amount:</td>
                <td className='text-end'>{currencyFormat.format(totalAmount)}</td>
              </tr>
              {serviceFee > 0 && (
                <tr className="fs-5">
                  <td>Service Fee</td>
                  <td className='text-end'>{currencyFormat?.format(serviceFee)}</td>
                </tr>
              )}
              <tr className="fs-5 fw-bolder">
                <td><strong>Amount Due Now:</strong></td>
                <td className='text-end'><strong>{currencyFormat?.format(amountDueNow)}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
        {message.error && <Alert className='' variant="danger">{message.error}<div><strong>Status Code:</strong> 500</div></Alert>}
        {message.success && <Alert className='' variant="success">{message.success}</Alert>}
        <div className='d-flex align-items-center justify-content-between'>
          <div className="d-flex my-3 justify-content-end">
            <button type="submit"
            disabled={isLoading}
            className="btn btn-primary fs-3">
              Proceed to Payment
            </button>
          </div>
        </div>
      </form>
      )}
    </div>
    <ScrollArrow />  
    </>
  );
};

export default PayLedger;
