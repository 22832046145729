import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../axiosInstance';
import { Form } from 'react-bootstrap';
import moment from 'moment';

type SchoolLevel = {
  school_name: string;
}
interface Extra {
  id: number;
  supplement_name: string;
  category: string;
  pricing: number;
  isRequired?: boolean;
  tuition_type: any[];
  school_level: SchoolLevel[];
  created_at: string;
  updated_at: string;
}

interface FormData {
  decodedProgram: string;
  student_Type: string;
  enrollment_extras: Extra[];
}

interface Campus {
  name: string;
  school_level: string[];
}

interface SelectExtrasProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const SelectExtras = ({ formData, setFormData }: any) => {
  const [extras, setExtras] = useState<Extra[]>([]);
  const [getCampusList, setCampusList] = useState<[]>([]);
  const [selectedExtras, setSelectedExtras] = useState<Extra[]>(() => {
    const savedExtras = sessionStorage.getItem('selectedExtras');
    return savedExtras ? JSON.parse(savedExtras) : formData?.enrollment_extras || [];
  });

  // Sync selectedExtras with formData.enrollment_extras on component load
  useEffect(() => {
    if (formData?.enrollment_extras?.length > 0) {
      setSelectedExtras(formData.enrollment_extras);
    }
  }, [formData.enrollment_extras]);

// Fetch campus from the server
  useEffect(() => {
    const fetchCampus = async () => {
      try {
        const { data } = await axiosPrivate.get(`/terra-programs/api/campus-list`);
        setCampusList(data.data);
      } catch (error) {
        console.error("Error fetching program data:", error);
      }
    };
    fetchCampus()
  },[])

  // Fetch extras from the server
  useEffect(() => {
    const fetchExtras = async () => {
      try {
        const response = await axiosPrivate.get(`/terra-programs/supplements/${formData.student_Type}`);
        const allExtras = response.data.extras;

        // Automatically add isRequired: true items to selectedExtras
        const requiredExtras = allExtras.filter((extra: Extra) => extra.isRequired);
        const filteredExtras = allExtras.filter((extra: Extra) => !extra.isRequired);

        // Only add required items that are not already in selectedExtras
        setSelectedExtras(prev => {
          const requiredNotInSelected = requiredExtras.filter(
            (requiredExtra: any) => !prev.some(e => e.id === requiredExtra.id)
          );
          const newSelectedExtras = [...prev, ...requiredNotInSelected];

          // Update formData when new required extras are added
          setFormData((prevFormData: any) => ({
            ...prevFormData,
            enrollment_extras: newSelectedExtras
          }));

          return newSelectedExtras;
        });

        const matchCampus = (campusName: string): Campus | null => {
          const campus = getCampusList.find((campus: Campus) =>
            campus.name.trim().toLowerCase() === campusName.trim().toLowerCase()
          );
          return campus || null;
        };

        const campus = formData.decodedProgram;
        const matchedCampus = matchCampus(campus);

        const matchedSchoolLevels: string[] = matchedCampus?.school_level || [];
        const relevantExtras = filteredExtras.filter((extra: Extra) =>
          Array.isArray(extra.school_level) &&
          extra.school_level.some((level: SchoolLevel) =>
            matchedSchoolLevels.includes(level.school_name)
          )
        );

        setExtras(relevantExtras);
      } catch (error) {
        console.error('Error fetching extras:', error);
      }
    };

    fetchExtras();
  }, [formData.student_Type, setFormData, getCampusList]);

  // Sync selectedExtras with sessionStorage and formData whenever it changes
  useEffect(() => {
    sessionStorage.setItem('selectedExtras', JSON.stringify(selectedExtras));

    // Update formData.enrollment_extras when selectedExtras changes
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      enrollment_extras: selectedExtras
    }));
  }, [selectedExtras, setFormData]);

  // Handle changes to checkbox (add/remove extra)
  const handleCheckboxChange = (extra: Extra) => {
    const isChecked = selectedExtras.some(e => e.id === extra.id);
    const updatedExtras = isChecked ? selectedExtras.filter(e => e.id !== extra.id) : [...selectedExtras, extra];

    // Update selectedExtras and formData together
    setSelectedExtras(updatedExtras);
  };

  const campus = formData?.decodedProgram
  const session = `${formData?.selectedSession?.session_name} (${moment.utc(formData?.selectedSession?.start_date).format('MM/DD/YYYY')} - ${moment.utc(formData?.selectedSession?.end_date).format('MM/DD/YYYY')}) ${formData?.selectedProgram?.name}` 

  return (
    <>
      <h2 className='mx-3' style={{ margin: '45px 0 30px' }}>{campus}: {session}</h2>
      <div className='mx-3' style={{ fontSize: 18 }}>
        <h3>Select Extras</h3>
        {extras.map((extra) => {
          if (extra.supplement_name === 'Tuition Protection Plan') return null
          return (
            <Form.Check
              className='mb-3 fs-3'
              key={extra.id}
              type="checkbox"
              id={`extra-${extra.id}`}
              label={`${extra.supplement_name} - $${extra.pricing}`}
              checked={selectedExtras.some(e => e.id === extra.id)}
              onChange={() => handleCheckboxChange(extra)}
            />
          )
        })}
      </div>
      {formData?.student_Type === 'residential' && ( 
        <h3 className='mx-3 mt-5 fs-3'>
          We do offer Unaccompanied Minor Service for an additional $50 fee. 
          Please contact us for more information.
        </h3>
      )}
    </>
  );
};

export default SelectExtras;
