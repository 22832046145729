import React from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

interface ChangeStatusModalProps {
  show: boolean;
  handleClose: () => void;
  selectedStatus: string;
  handleChangeStatus: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isError: string;
  hasAdmin: boolean;
  handleSubmitStatusChange: (studentUUID: string, programUUID: string, formID: string) => void;
  formData: any;
}

const ChangeStatusModal: React.FC<ChangeStatusModalProps> = ({
  show,
  handleClose,
  selectedStatus,
  handleChangeStatus,
  isError,
  hasAdmin,
  handleSubmitStatusChange,
  formData,
}) => (
  <Modal show={show} onHide={handleClose} backdrop='static' centered>
    <Modal.Header closeButton>
      <Modal.Title>Change Form Status</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group controlId="formStatus">
        <Form.Label>Select Form Status</Form.Label>
        <Form.Select value={selectedStatus} onChange={handleChangeStatus}>
          <option value="Incomplete">Incomplete</option>
          <option value="Submitted - Needs Review">Submitted - Needs Review</option>
          <option value="Completed">Completed</option>
        </Form.Select>
      </Form.Group>
      {isError && <div className="text-danger">{isError}</div>}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>Close</Button>
      {hasAdmin && (
        <Button variant="primary" onClick={() => handleSubmitStatusChange(formData.studentUUID, formData.programUUID, formData.formID)}>
          Save Changes
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);

export default ChangeStatusModal;